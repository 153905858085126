/* eslint-disable react/jsx-no-target-blank */

import { useEffect, useState } from 'react';
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub';
import { AsideMenuItem } from './AsideMenuItem';

import { useRBAC } from '../../../../context/RBACContext';
import { sidebarConfig } from '../../../../common/config/sidebar/SidebarConfig';

export const MenuMain = () => {
    const { userRole } = useRBAC();
    const [sidebarDetails, setSidebarDetails] = useState<any>([]);

    useEffect(() => {
        const customSidebar = sidebarConfig?.filter((sConfig) => {
            return sConfig?.acceptedRole?.includes(userRole);
        });
        setSidebarDetails(customSidebar);
    }, [userRole]);

    return (
        <>
            {sidebarDetails?.map((sidebar: any) => {
                return (
                    <>
                        {/* Title */}
                        {sidebar?.hasTitle === true && (
                            <div className="menu-item">
                                <div className="menu-content pt-4 pb-2">
                                    <span className="menu-section text-muted text-uppercase fs-8 ls-1">
                                        {sidebar?.moduleName}
                                    </span>
                                </div>
                            </div>
                        )}

                        {/* Menu */}
                        {sidebar?.nestedMenu !== true ? (
                            sidebar?.menuItems?.map((menuItem: any) => {
                                return (
                                    <>
                                        {!menuItem.hide && (
                                            <AsideMenuItem
                                                key={menuItem.title}
                                                to={menuItem?.to}
                                                hasBullet={menuItem?.hasBullet}
                                                icon={menuItem?.icon}
                                                title={menuItem?.title}
                                                fontIcon={menuItem?.fontIcon}
                                            />
                                        )}
                                    </>
                                );
                            })
                        ) : (
                            <AsideMenuItemWithSub
                                to={sidebar?.subMenu?.to}
                                title={sidebar?.subMenu?.title}
                                icon={sidebar?.subMenu?.icon}
                            >
                                {sidebar?.menuItems?.map((menuItem: any) => {
                                    return (
                                        <>
                                            {!menuItem.hide && (
                                                <AsideMenuItem
                                                    key={menuItem.title}
                                                    to={menuItem?.to}
                                                    hasBullet={
                                                        menuItem?.hasBullet
                                                    }
                                                    icon={menuItem?.icon}
                                                    title={menuItem?.title}
                                                    fontIcon={
                                                        menuItem?.fontIcon
                                                    }
                                                />
                                            )}
                                        </>
                                    );
                                })}
                            </AsideMenuItemWithSub>
                        )}

                        {/* Divider */}
                        {sidebar?.divider === true && (
                            <div className="menu-item">
                                <div className="menu-content">
                                    <div className="separator mx-1 my-4"></div>
                                </div>
                            </div>
                        )}
                    </>
                );
            })}
        </>
    );
};
