// A global Info about the app

import { useEffect, useState } from "react";
import { AdminResource, fmResource } from "../../rbac";

export const useAppInfo = () => {
    const logged = localStorage.getItem("userRole");

    const resource = logged === "FACILITY_MANAGER" ? fmResource : AdminResource;
    // User info
    const [loggedInUser, setLoggedInUser] = useState("");

    useEffect(() => {
        setLoggedInUser(resource?.roleId?.roleName);
    }, [resource]);

    // const loggedInUser = useMemo(() => resource?.roleId?.roleName, []);

    return {
        loggedInUser,
    };
};
