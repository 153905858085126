/* eslint-disable import/no-anonymous-default-export */
/**
 *  Route & User level RBAC is configure here
 */
import { CHWOverview } from '../../../pages/chw/CHWOverview';
import CHWPage from '../../../pages/chw/CHWPage';
import { CHWOnboarding } from '../../../pages/chw/onboarding/CHWOnboarding';
import DashboardPage from '../../../pages/dashboard/DashboardPage';
import CreateFocusedGroupPage from '../../../pages/focused-group/create-group/CreateFocusedGroupPage';
import FocusedGroupOverview from '../../../pages/focused-group/management/FocusedGroupOverview';
import FocusedGroupPage from '../../../pages/focused-group/management/FocusedGroupPage';
import FormPage from '../../../pages/formbuilder/FormPage';
import BatchListOverview from '../../../pages/healthCard/BatchListOverview';
import BatchListPage from '../../../pages/healthCard/BatchListPage';
import GenerateCards from '../../../pages/healthCard/generateCard/GenerateCards';
import UnusedCardsTable from '../../../pages/healthCard/table/UnusedCardsTable';
import VitalDetails from '../../../pages/patient/components/VitalDetails';
import PatientOverview from '../../../pages/patient/PatientOverview';
import PatientPage from '../../../pages/patient/PatientPage';
import ReportPage from '../../../pages/report/ReportPage';
import TaskDetails from '../../../pages/task/components/TaskDetails';
import TaskInsights from '../../../pages/task/components/TaskInsights';
import TaskPage from '../../../pages/task/TaskPage';
import ContentManagementPage from '../../../pages/web-content/content-management/ContentManagementPage';
import ContentOverview from '../../../pages/web-content/content-management/ContentOverview';
import CreateCategoryPage from '../../../pages/web-content/create-category/CreateCategoryPage';
import CreateContentPage from '../../../pages/web-content/create-content/CreateContentPage';
import UnassignedTaskPage from '../../../pages/task/UnassignedTaskPage';
import FacilityOnboarding from '../../../pages/facility/new-facility/onboarding/FacilityOnboarding';
import FacilityManagerOnboarding from '../../../pages/facility/facility-manager/onboarding/FacilityManagerOnboarding';
import FacilityPage from '../../../pages/facility/facility-list/FacilityPage';
import AppointmentPage from '../../../pages/patient/AppointmentPage';
import TaskManagementPage from '../../../pages/task/create-category/TaskManagementPage';
import PerformanceAnalysis from '../../../pages/performance-analysis/PerformanceAnalysis';
import CategoryList from '../../../pages/web-content/category-list/CategoryList';
import { SettingsPage } from '../../../pages/settings/SettingsPage';
import DoctorPage from '../../../pages/doctor/DoctorPage';
import { DoctorOverview } from '../../../pages/doctor/DoctorOverview';
import { DoctorOnboarding } from '../../../pages/doctor/features/onboarding/DoctorOnboarding';
import CourseCurriculum from '../../../pages/web-content/content-management/components/course-curriculum/CourseCurriculum';
import VisitFormPage from '../../../pages/focused-group/management/form-structure/VisitFormPage';

import BeneficiaryEntries from '../../../pages/focused-group/management/components/BeneficiaryEntries';
import BeneficiaryVitals from '../../../pages/focused-group/management/components/BeneficiaryVitals';
import PatientEntries from '../../../pages/patient/features/assigned-focused-group/table-view/nested-view/PatientEntries';
import PatientVitals from '../../../pages/patient/features/assigned-focused-group/table-view/nested-view/PatientVitals';

import { allRoles, permittedRoles } from '../rbac/types/rbac.types';
// import InvalidFacilityScreen from "../../../components/FallbackScreens/InvalidFacilityScreen";
import { hasAccessToModule, hasAccessToSubModule } from '../../../rbac';
import { Permissions } from '../../../rbac/types';

import IndentListPage from '../../../pages/supply-chain/indent/indent-list/IndentListPage';
import ModifyIndentPage from '../../../pages/supply-chain/indent/modify-indent/ModifyIndentPage';
import IndentDetails from '../../../pages/supply-chain/indent/indent-details/IndentDetails';
import PurchaseOrderList from '../../../pages/supply-chain/purchase-order/purchase-order-list';
import PurchaseOrderDetails from '../../../pages/supply-chain/purchase-order/purchase-order-details';
import AddGrn from '../../../pages/supply-chain/goods-receipt-note/add-GRN';
import GRNListPage from '../../../pages/supply-chain/goods-receipt-note/grn-list';
import SalesOrderList from '../../../pages/supply-chain/sales-order/sales-order-list';
import SalesOrderDetails from '../../../pages/supply-chain/sales-order/sales-order-details';
import MasterProductPage from '../../../pages/supply-chain/product-master/view-product-list';
import ModifySalesOrder from '../../../pages/supply-chain/sales-order/modify-sales-order';
import CreatePurchaseOrder from '../../../pages/supply-chain/purchase-order/create-purchase-order';
import FacilityStockPage from '../../../pages/supply-chain/view-stock/facility-stock';
import CHWStockPage from '../../../pages/supply-chain/view-stock/chw-stock';
import BillingOrder from '../../../pages/supply-chain/sales-order/billing';
import PaymentList from '../../../pages/supply-chain/payment-list';
import HealthCardTypePage from '../../../pages/healthCard/features/HealthCardTypePage';
import IkureConnectPage from '../../../pages/task/IkureConnectPage';

const {
    distribute_button,
    filter_button,
    detail_button,
    assign_button,
    create,
    create_form,
    delete_button,
    export_button,
    read,
    reassign_button,
    search_bar,
    update,
    view_form,
} = Permissions;

export default [
    {
        isRoute: true,
        component: DashboardPage,
        path: '/dashboard',
        title: 'DashboardPage',
        exact: true,
        permission: [
            permittedRoles.facilityManager,
            permittedRoles.organizationAdmin,
        ],
        canDisplay: hasAccessToModule('DASHBOARD', read),
        children: [],
    },
    {
        isRoute: true,
        component: CHWPage,
        path: '/chw/chw-management',
        title: 'CHWPage',
        exact: true,
        permission: allRoles,
        canDisplay: hasAccessToModule('LIST_CHW', read),
        children: [],
    },
    {
        isRoute: true,
        component: CHWOverview,
        path: '/chw/overview',
        title: 'CHWOverview',
        permission: allRoles,
        canDisplay: hasAccessToSubModule('LIST_CHW', 'CONTAINER_CHW', read),
        children: [],
    },
    {
        isRoute: true,
        component: CHWOnboarding,
        path: '/chw/chw-onboarding',
        title: 'CHWOnboarding',
        exact: true,
        canDisplay: hasAccessToModule('ONBOARD_CHW', read),
        permission: [
            permittedRoles.organizationAdmin,
            permittedRoles.facilityManager,
        ],
        children: [],
    },
    {
        isRoute: true,
        component: DoctorPage,
        path: '/doctor/doctor-management',
        title: 'DoctorPage',
        exact: true,
        permission: [permittedRoles.organizationAdmin],
        canDisplay: hasAccessToModule('LIST_DOCTOR', read),
        children: [],
    },
    {
        isRoute: true,
        component: DoctorOverview,
        path: '/doctor/overview',
        title: 'DoctorOverview',
        permission: [permittedRoles.organizationAdmin],
        canDisplay: hasAccessToSubModule(
            'LIST_DOCTOR',
            'CONTAINER_DOCTOR',
            read
        ),
        children: [],
    },
    {
        isRoute: true,
        component: DoctorOnboarding,
        path: '/doctor/doctor-onboarding',
        title: 'DoctorOnboarding',
        exact: true,
        permission: [permittedRoles.organizationAdmin],
        canDisplay: hasAccessToModule('ONBOARD_DOCTOR', read),
        children: [],
    },
    {
        isRoute: true,
        component: FacilityPage,
        path: '/facility/facility-management',
        title: 'FacilityPage',
        exact: true,
        permission: [permittedRoles.organizationAdmin],
        canDisplay: hasAccessToModule('LIST_FACILITY', read),
        children: [],
    },
    {
        isRoute: true,
        component: FacilityOnboarding,
        path: '/facility/facility-onboarding',
        title: 'FacilityOnboarding',
        exact: true,
        permission: [permittedRoles.organizationAdmin],
        canDisplay: hasAccessToModule('ONBOARD_FACILITY', read),
        children: [],
    },
    {
        isRoute: true,
        component: FacilityManagerOnboarding,
        path: '/facility/facility-manager-onboarding',
        title: 'FacilityManagerOnboarding',
        exact: true,
        permission: [permittedRoles.organizationAdmin],
        canDisplay: hasAccessToModule('ONBOARD_FACILITY_MANAGER', read),
        children: [],
    },
    {
        isRoute: true,
        component: PatientPage,
        path: '/patient/patient-management',
        exact: true,
        title: 'PatientPage',
        permission: [
            permittedRoles.facilityManager,
            permittedRoles.organizationAdmin,
        ],
        canDisplay: hasAccessToModule('LIST_PATIENT', read),
        children: [],
    },
    {
        isRoute: true,
        component: AppointmentPage,
        path: '/patient/patient-appointment',
        exact: true,
        title: 'AppointmentPage',
        permission: [
            permittedRoles.facilityManager,
            permittedRoles.organizationAdmin,
        ],
        canDisplay: hasAccessToModule(
            'LIST_FACILITY_LEVEL_PATIENT_APPOINTMENT',
            read
        ),
        children: [],
    },
    {
        isRoute: true,
        component: PatientEntries,
        // path: "/patient/:id/overview/patient-entries",
        path: '/patient/overview/patient-entries',
        exact: true,
        title: 'PatientEntries',
        canDisplay: hasAccessToSubModule(
            'LIST_PATIENT',
            'LIST_ASSIGNED_FOCUSED_GROUPS',
            Permissions.read
        ),
        permission: [
            permittedRoles.facilityManager,
            permittedRoles.organizationAdmin,
        ],
        children: [],
    },
    {
        isRoute: true,
        component: PatientVitals,
        path: '/patient/overview/patient-vitals',
        exact: true,
        title: 'PatientVitals',
        canDisplay: hasAccessToSubModule(
            'LIST_PATIENT',
            'LIST_ASSIGNED_FOCUSED_GROUPS',
            Permissions.read
        ),
        permission: [
            permittedRoles.facilityManager,
            permittedRoles.organizationAdmin,
        ],
        children: [],
    },
    {
        isRoute: true,
        component: PatientOverview,
        path: '/patient/overview',
        title: 'PatientOverview',
        // exact: true,
        permission: [
            permittedRoles.facilityManager,
            permittedRoles.organizationAdmin,
        ],
        canDisplay: hasAccessToSubModule(
            'LIST_PATIENT',
            'CONTAINER_PATIENT',
            read
        ),
        children: [
            {
                isRoute: true,
                component: VitalDetails,
                // path: "/patient/:id/overview/vital-details",
                path: '/vital-details',
                title: 'VitalDetails',
                exact: true,
                permission: [
                    permittedRoles.organizationAdmin,
                    permittedRoles.facilityManager,
                ],
            },
        ],
    },
    {
        isRoute: true,
        component: FormPage,
        path: '/form-management',
        title: 'FormPage',
        permission: [
            permittedRoles.facilityManager,
            permittedRoles.organizationAdmin,
        ],
        canDisplay: hasAccessToModule('FORM_BUILDER', read),
        children: [],
    },
    {
        isRoute: true,
        component: FocusedGroupPage,
        path: '/focused-group/management',
        exact: true,
        title: 'FocusedGroupPage',
        permission: [
            permittedRoles.facilityManager,
            permittedRoles.organizationAdmin,
        ],
        canDisplay: hasAccessToModule('LIST_FOCUSED_GROUP', read),
        children: [],
    },
    {
        isRoute: true,
        component: CreateFocusedGroupPage,
        path: '/focused-group/create-focused-group',
        exact: true,
        title: 'CreateFocusedGroupPage',
        permission: [
            permittedRoles.facilityManager,
            permittedRoles.organizationAdmin,
        ],
        canDisplay: hasAccessToModule('CREATE_FOCUSED_GROUP', read),
        children: [],
    },
    {
        isRoute: true,
        component: FocusedGroupOverview,
        path: '/focused-group/forms',
        title: 'FocusedGroupPage',
        permission: [
            permittedRoles.facilityManager,
            permittedRoles.organizationAdmin,
        ],
        canDisplay: hasAccessToSubModule(
            'LIST_FOCUSED_GROUP',
            'CONTAINER_FOCUSED_GROUP',
            read
        ),
        children: [],
    },
    {
        isRoute: true,
        component: VisitFormPage,
        path: '/focused-group/form-entries',
        title: 'VisitFormPage',
        permission: [
            permittedRoles.facilityManager,
            permittedRoles.organizationAdmin,
        ],
        canDisplay: hasAccessToSubModule(
            'LIST_FOCUSED_GROUP',
            'LIST_FORM_ENTRIES',
            read
        ),
        children: [],
    },
    {
        isRoute: true,
        component: BeneficiaryEntries,
        path: '/focused-group/entry-details',
        title: 'BeneficiaryEntries',
        permission: [
            permittedRoles.facilityManager,
            permittedRoles.organizationAdmin,
        ],
        canDisplay: hasAccessToSubModule(
            'LIST_FOCUSED_GROUP',
            'LIST_BENEFICIARY_ENTRIES',
            read
        ),
        children: [],
    },
    {
        isRoute: true,
        component: BeneficiaryVitals,
        path: '/focused-group/vital-details',
        title: 'BeneficiaryVitals',
        permission: [
            permittedRoles.facilityManager,
            permittedRoles.organizationAdmin,
        ],
        canDisplay: hasAccessToSubModule(
            'LIST_FOCUSED_GROUP',
            'LIST_BENEFICIARY_VITALS',
            read
        ),
        children: [],
    },
    {
        isRoute: true,
        component: HealthCardTypePage,
        path: '/healthcard/list',
        title: 'HealthCardTypePage',
        permission: [
            permittedRoles.facilityManager,
            permittedRoles.organizationAdmin,
        ],
        canDisplay: hasAccessToModule('LIST_BATCH', read),
        children: [],
    },
    {
        isRoute: true,
        component: BatchListPage,
        path: '/batch/list',
        title: 'BatchListPage',
        permission: [
            permittedRoles.facilityManager,
            permittedRoles.organizationAdmin,
        ],
        canDisplay: hasAccessToModule('LIST_BATCH', read),
        children: [],
    },
    {
        isRoute: true,
        component: BatchListOverview,
        path: '/batch/overview',
        title: 'BatchListOverview',
        permission: [
            permittedRoles.facilityManager,
            permittedRoles.organizationAdmin,
        ],
        canDisplay: hasAccessToSubModule('LIST_BATCH', 'CONTAINER_BATCH', read),
        children: [],
    },
    {
        isRoute: true,
        component: UnusedCardsTable,
        path: '/healthcard/unsused',
        title: 'UnusedCardsTable',
        permission: [
            permittedRoles.facilityManager,
            permittedRoles.organizationAdmin,
        ],
        canDisplay: hasAccessToModule('LIST_UNUSED_HEALTHCARDS', read),
        children: [],
    },
    {
        isRoute: true,
        component: GenerateCards,
        path: '/healthcard/generate',
        exact: true,
        title: 'GenerateCards',
        permission: [
            permittedRoles.facilityManager,
            permittedRoles.organizationAdmin,
        ],
        canDisplay: hasAccessToModule('GENERATE_HEALTHCARDS', create),
        children: [],
    },
    {
        isRoute: true,
        component: TaskPage,
        path: '/task/task-list',
        exact: true,
        title: 'TaskPage',
        permission: [
            permittedRoles.facilityManager,
            permittedRoles.organizationAdmin,
        ],
        canDisplay: hasAccessToModule('LIST_TASK', read),
        children: [],
    },
    {
        isRoute: true,
        component: UnassignedTaskPage,
        path: '/task/unassigned-task-list',
        exact: true,
        title: 'UnassignedTaskPage',
        permission: [
            permittedRoles.facilityManager,
            permittedRoles.organizationAdmin,
        ],
        canDisplay: hasAccessToModule('LIST_UNASSIGNED_TASK', read),
        children: [],
    },
    {
        isRoute: true,
        component: TaskInsights,
        path: '/task/task-insights',
        exact: true,
        title: 'TaskInsights',
        permission: [
            permittedRoles.facilityManager,
            permittedRoles.organizationAdmin,
        ],
        canDisplay: hasAccessToModule('INSIGHTS_TASK', read),
        children: [],
    },
    {
        isRoute: true,
        component: IkureConnectPage,
        path: '/task/ikure-connect',
        exact: true,
        title: 'IkureConnect',
        permission: [
            permittedRoles.facilityManager,
            permittedRoles.organizationAdmin,
        ],
        canDisplay: hasAccessToModule('LIST_IKURE_CONNECT', read),
        children: [],
    },
    {
        isRoute: true,
        component: TaskDetails,
        path: '/task/task-details',
        exact: true,
        title: 'TaskDetails',
        permission: [
            permittedRoles.facilityManager,
            permittedRoles.organizationAdmin,
        ],
        canDisplay: hasAccessToSubModule('LIST_TASK', 'CONTAINER_TASK', read),
        children: [],
    },
    {
        isRoute: true,
        component: ReportPage,
        path: '/report/report-management',
        title: 'ReportPage',
        permission: [
            permittedRoles.facilityManager,
            permittedRoles.organizationAdmin,
        ],
        canDisplay: hasAccessToModule('LIST_REPORT', read),
        children: [],
    },
    {
        isRoute: true,
        component: ContentManagementPage,
        path: '/training/course-management',
        title: 'ContentManagementPage',
        permission: [
            permittedRoles.facilityManager,
            permittedRoles.organizationAdmin,
        ],
        canDisplay: hasAccessToModule('LIST_COURSE', read),
        children: [],
    },
    {
        isRoute: true,
        component: CreateContentPage,
        path: '/training/create-course',
        title: 'CreateContentPage',
        permission: [
            permittedRoles.facilityManager,
            permittedRoles.organizationAdmin,
        ],
        canDisplay: hasAccessToModule('CREATE_COURSE', create),
        children: [],
    },
    {
        isRoute: true,
        component: CreateCategoryPage,
        path: '/training/categories',
        title: 'CreateCategoryPage',
        permission: [
            permittedRoles.facilityManager,
            permittedRoles.organizationAdmin,
        ],
        canDisplay: hasAccessToModule('CREATE_CATEGORY_SUBCATEGORY', create),
        children: [],
    },
    {
        isRoute: true,
        component: CategoryList,
        path: '/training/category-list',
        title: 'Category List',
        permission: [
            permittedRoles.facilityManager,
            permittedRoles.organizationAdmin,
        ],
        canDisplay: hasAccessToModule('LIST_CATEGORY_SUBCATEGORY', read),
        children: [],
    },
    {
        isRoute: true,
        component: ContentOverview,
        path: '/training/overview',
        title: 'ContentOverview',
        permission: [
            permittedRoles.facilityManager,
            permittedRoles.organizationAdmin,
        ],
        canDisplay: hasAccessToSubModule(
            'LIST_COURSE',
            'CONTAINER_COURSE',
            read
        ),
        children: [],
    },
    {
        isRoute: true,
        component: PerformanceAnalysis,
        path: '/performance-analysis',
        title: 'PerformanceAnalysis',
        canDisplay: hasAccessToModule('PERFORMANCE_MONITORING', read),
        permission: [
            permittedRoles.facilityManager,
            permittedRoles.organizationAdmin,
        ],
        children: [],
    },
    {
        isRoute: true,
        component: SettingsPage,
        path: '/settings',
        title: 'SettingsPage',
        permission: [
            permittedRoles.facilityManager,
            permittedRoles.organizationAdmin,
        ],
        canDisplay: hasAccessToModule('SETTINGS', read),
        children: [],
    },
    {
        isRoute: true,
        component: IndentListPage,
        path: '/indent/indent-list',
        title: 'IndentListPage',
        permission: [
            permittedRoles.facilityManager,
            permittedRoles.organizationAdmin,
        ],
        canDisplay: hasAccessToModule('LIST_INDENT', read),
        children: [],
    },
    {
        isRoute: true,
        component: IndentDetails,
        path: '/indent/indent-details',
        title: 'IndentDetails',
        permission: [
            permittedRoles.facilityManager,
            permittedRoles.organizationAdmin,
        ],
        canDisplay: hasAccessToSubModule('LIST_INDENT', 'INDENT_DETAILS', read),
        children: [],
    },
    {
        isRoute: true,
        component: CreatePurchaseOrder,
        path: '/indent/create-po',
        title: 'CreatePurchaseOrder',
        permission: [permittedRoles.organizationAdmin],
        // canDisplay: hasAccessToSubModule(
        //     "LIST_INDENT",
        //     "CREATE_PURCHASE_ORDER",
        //     create
        // ),
        // TODO: check the CREATE_PURCHASE_ORDER subtype
        canDisplay: hasAccessToSubModule('LIST_INDENT', 'INDENT_DETAILS', read),
        children: [],
    },
    {
        isRoute: true,
        component: ModifyIndentPage,
        path: '/indent/modify-indent',
        title: 'ModifyIndentPage',
        permission: [
            permittedRoles.facilityManager,
            permittedRoles.organizationAdmin,
        ],
        canDisplay: hasAccessToSubModule('LIST_INDENT', 'MODIFY_INDENT', read),
        children: [],
    },
    {
        isRoute: true,
        component: PurchaseOrderList,
        path: '/purchase-order/list',
        title: 'PurchaseOrderList',
        permission: [
            permittedRoles.facilityManager,
            permittedRoles.organizationAdmin,
        ],
        canDisplay: hasAccessToModule('LIST_PURCHASE_ORDER', read),
        children: [],
    },
    {
        isRoute: true,
        component: PurchaseOrderDetails,
        path: '/purchase-order/details',
        title: 'PurchaseOrderDetails',
        permission: [
            permittedRoles.facilityManager,
            permittedRoles.organizationAdmin,
        ],
        canDisplay: hasAccessToSubModule(
            'LIST_PURCHASE_ORDER',
            'PURCHASE_ORDER_DETAILS',
            read
        ),
        children: [],
    },
    {
        isRoute: true,
        component: AddGrn,
        path: '/goods-receipt-note/add',
        title: 'AddGrn',
        permission: [permittedRoles.facilityManager],
        canDisplay: hasAccessToSubModule(
            'LIST_PURCHASE_ORDER',
            'CREATE_GRN',
            create
        ),
        children: [],
    },
    {
        isRoute: true,
        component: GRNListPage,
        path: '/goods-receipt-note/list',
        title: 'GRNListPage',
        permission: [
            permittedRoles.facilityManager,
            permittedRoles.organizationAdmin,
        ],
        canDisplay: hasAccessToModule('LIST_GRN', read),
        children: [],
    },
    // TODO : (sn) need to complete
    {
        isRoute: true,
        component: SalesOrderList,
        path: '/sales-order/list',
        title: 'SalesOrderList',
        permission: [
            permittedRoles.facilityManager,
            permittedRoles.organizationAdmin,
        ],
        canDisplay: hasAccessToModule('LIST_SALES_ORDER', read),
        children: [],
    },
    {
        isRoute: true,
        component: SalesOrderDetails,
        path: '/sales-order/details',
        title: 'SalesOrderDetails',
        permission: [
            permittedRoles.facilityManager,
            permittedRoles.organizationAdmin,
        ],
        canDisplay: hasAccessToSubModule(
            'LIST_SALES_ORDER',
            'SALE_ORDER_DETAILS',
            read
        ),
        children: [],
    },
    {
        isRoute: true,
        component: BillingOrder,
        path: '/sales-order/add-bill',
        title: 'Billing',
        permission: [
            permittedRoles.facilityManager,
            permittedRoles.organizationAdmin,
        ],
        canDisplay: hasAccessToModule('LIST_SALES_ORDER', read),
        children: [],
    },
    {
        isRoute: true,
        component: ModifySalesOrder,
        path: '/sales-order/create',
        title: 'ModifySalesOrder',
        permission: [
            permittedRoles.facilityManager,
            permittedRoles.organizationAdmin,
        ],
        canDisplay: hasAccessToSubModule('LIST_INDENT', 'MODIFY_INDENT', read),
        // canDisplay: hasAccessToModule("GRN_LIST", read),
        children: [],
    },
    {
        isRoute: true,
        component: FacilityStockPage,
        path: '/facility-stock/list',
        title: 'FacilityStockPage',
        permission: [
            permittedRoles.facilityManager,
            permittedRoles.organizationAdmin,
        ],

        canDisplay: hasAccessToModule('LIST_FACILITY_STOCK', read),
        children: [],
    },
    {
        isRoute: true,
        component: CHWStockPage,
        path: '/chw-stock/list',
        title: 'CHWStockPage',
        permission: [
            permittedRoles.facilityManager,
            permittedRoles.organizationAdmin,
        ],
        canDisplay: hasAccessToModule('LIST_CHW_STOCK', read),
        children: [],
    },
    {
        isRoute: true,
        component: MasterProductPage,
        path: '/master-product/list',
        title: 'MasterProductPage',
        permission: [permittedRoles.organizationAdmin],

        canDisplay: hasAccessToModule('LIST_MASTER_PRODUCT', read),
        children: [],
    },
    {
        isRoute: true,
        component: PaymentList,
        path: '/payment-list',
        title: 'PaymentList',
        permission: [
            permittedRoles.facilityManager,
            permittedRoles.organizationAdmin,
        ],
        canDisplay: hasAccessToSubModule(
            'LIST_SALES_ORDER',
            'SALE_ORDER_DETAILS',
            read
        ),
        children: [],
    },
    // Not in use (Routes)
    {
        isRoute: true,
        component: CourseCurriculum,
        path: '/training/overview/course-curriculum',
        title: 'ContentCurriculum',
        permission: [
            permittedRoles.facilityManager,
            permittedRoles.organizationAdmin,
        ],
        children: [],
    },
    {
        isRoute: true,
        component: TaskManagementPage,
        path: '/task/task-management',
        exact: true,
        title: 'TaskManagementPage',
        permission: [
            permittedRoles.facilityManager,
            permittedRoles.organizationAdmin,
        ],
        children: [],
    },
];
